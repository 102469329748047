import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";

export default class DiscountAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            discount :"",
            discounttype:"percent",
            searchtype:"contain",
            used:"0",
            description:"",
            minorder :"100",
            maxorder:"0",
            firstorderonly:"no",
            discountmethod:"email",
            count:"0",
            area:"",
            arealist:[],
            compound:"",
            compoundlist:[],
            code:"",
            email :"",
            phone :"",
            _id:"",
            loading : false
        };
        this.pathname = "discount";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                compound:edit.compound && edit.compound.id,
                area:edit.area && edit.area.id
            });
        }

        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("compound");
        if(responseuni){
            const data = responseuni.data.data;
            let compoundlist = []
            data.map(p =>
                compoundlist.push( { value: p._id, label: p.name })
            )

            this.setState({
                compoundlist
            });
        }

        responseuni = await this.engine.getItemlistDirect("area");
        if(responseuni){
            const data = responseuni.data.data;
            let arealist = []
            data.map(p =>
                arealist.push( { value: p._id, label: p.name })
            )

            this.setState({
                arealist
            });
        }
    }




    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })


        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200 && response.data.success){
            this.props.history.push('/admin/'+this.pathname);
        }else{
            Utilites.showErrorMessage(response.data.message)
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading, discount ,
            discounttype,
            description,
            minorder ,
            count,
            email ,
            searchtype,
            compound,
            compoundlist,
            area,
            discountmethod,
            maxorder,
            arealist,
            code,
            phone,firstorderonly,used  }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label="code" name="code" data={code} required={false}   changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="Email" name="email" data={email}  required={false}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Phone" name="phone" data={phone} required={false}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="Compound"  name="compound"  defaultValue={compound}  data={compoundlist} changeInput={this.changeInput.bind(this)} />

                                        <SelectRow label="Discount method"  name="discountmethod"  defaultValue={discountmethod}  data={this.createSelectValue(["email","area","compound"])} changeInput={this.changeInput.bind(this)} />

                                        <SelectRow label="Area"  name="area"  defaultValue={area}  data={arealist} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="Discount" type={"number"} name="discount" data={discount}   changeInput={this.changeInput.bind(this)} />

                                        <SelectRow label="Search Type"  name="searchtype"  defaultValue={searchtype}  data={this.createSelectValue(["excat","contain"])} changeInput={this.changeInput.bind(this)} />

                                        <SelectRow label="Discount Type"  name="discounttype"  defaultValue={discounttype}  data={this.createSelectValue(["fixed","percent"])} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Min Order Amount" type={"number"} name="minorder" data={minorder.toString()}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Max Discounted Order Amount" type={"number"} name="maxorder" data={maxorder.toString()}   changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="count" name="count" data={count.toString()} type={"number"}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="used" name="used" data={used.toString()} readonly={true}   />

                                        <FormRow required={false} label="description" name="description" type={"textarea"} data={description} changeInput={this.changeInput.bind(this)}  />


                                        <SelectRow label="Apply on First Order Only"  name="firstorderonly"  defaultValue={firstorderonly}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


