import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,

    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";

class CategoryList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
        };
        this.pathname = "category";
        this.engine = new RequestEngine();
    }


    async handleDelete(valueid) {
        console.log(valueid);
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage(0);
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>

                {!item.system && <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>}

            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage(page,filtered = []) {

        const response = await  this.engine.postItem(this.pathname,"/list/"+page,filtered);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    count:response.data.data.count,
                    page:page,
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            catid:item._id,
                            data:item,
                            name: item.name ,
                            parent:item.category && item.category.name,
                            img: item.picture.length>1 ? <img src={Constants.serverlink+"/upload/"+item.picture} className={"imgsponser"} />:"-" ,
                            order: item.order,
                            status:item.status==1?<span style={{color:"green"}}>Activated</span>:<span style={{color:"red"}}>Disabled</span>,
                            category: item.category ? item.category.name : "-",
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage(page) {
        this.props.loadingAction(true);
        this.callPage(page)
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        const {dataSource,isLoading,count,page} = this.state;



        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })

        let columns = [
            {
                Header: "Category ID",
                accessor: "catid"
            },
            {
                Header: "Title",
                accessor: "name"
            },
            {
                Header: "Status",
                accessor: "status"
            },
            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>
                                </CardHeader>
                                <CardBody>

                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        //showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />



                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(CategoryList);
