import React from "react";
import _ from "lodash";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle, PaginationItem, PaginationLink, Pagination
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import SelectRow from "../../components/Select/SelectRow";
import FormRow from "../../components/Row/FormRow";
import {AvForm} from "availity-reactstrap-validation";
import {Utilites} from "../../core/Utilites";

class OrderList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            deliverydate:"",
            deliverydatelist:[],
            page:0,
            type:"admin",
            pageSize:10,
            count:0,
            innerloading:true,
        };
        this.pathname = "order";
        this.engine = new RequestEngine();
    }



    async handleDelete(valueid) {
        if(window.confirm("are you sure ?")){
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname,valueid)
            if(result){
                this.handlePage();
            }
            this.props.loadingAction(false)
        }
    }

    async handleCopyPaymentLink(index) {
        const item = this.state.dataSource[index].data

        const paymentlink = "https://freshvery.com/minipayment/"+item.id;

        navigator.clipboard.writeText(paymentlink).then(() => {
           Utilites.showSucessMessage("Payment Link Copied!")
            /* Resolved - text copied to clipboard successfully */
        },() => {
            Utilites.showSucessMessage('Failed to copy');
            /* Rejected - text failed to copy to the clipboard */
        });

    }


    handleView(index,dataid) {
        const data = this.state.dataSource[index].data


        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/view/'+dataid,
            data
        });

    }

    handleAssign(index,dataid) {
        const data = this.state.dataSource[index].data


        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/assign/'+dataid,
            data
        });

    }

    async handleToggle(valueid,status) {
        console.log(valueid);
        this.props.loadingAction(true)
        const result = await this.engine.getItem(this.pathname,"/toggle/"+valueid+"/"+status)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)
    }
    handleActions(index,item) {

        const {type} = this.state
        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem  eventKey="1" onClick={()=>this.handleView(index,item.id)} >View</DropdownItem>
                {type=="admin" && (item.paymenttype==2 || item.paymenttype==3) &&  <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleCopyPaymentLink(index)}>Copy Payment Link</DropdownItem>}
                {type=="admin" && <DropdownItem variant="danger" eventKey="0" onClick={() => this.handleAssign(index,item.id)}>Assign Driver</DropdownItem>}
                {type=="admin" && <DropdownItem  eventKey="2" onClick={() => this.handleToggle(item._id,"confirmed")} >Mark as Confirmed </DropdownItem>}
                {type=="admin" && <DropdownItem  eventKey="3" onClick={() => this.handleToggle(item._id,"pending")} >Mark as Pending </DropdownItem>}
                {type=="admin" && <DropdownItem  eventKey="3" onClick={() => this.handleToggle(item._id,"canceled")} >Mark as Canceled </DropdownItem>}
                {type=="admin" && <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>}

            </DropdownMenu>
        </UncontrolledDropdown>)
    }



    async handleViewBenExport() {
        if(this.state.deliverydate.length<2){
            Utilites.showErrorMessage("Please select delivery date");
            return;
        }

        let deliverydate = this.state.deliverydate.replace('/', '-')
        deliverydate = deliverydate.replace('/', '-')
        await  this.engine.exportCSV("order","/export/"+deliverydate);
    }


    async handleViewBenExportAll() {

        await  this.engine.exportCSV("order","/export/all")
    }
    putrowstatus(item){

        if(item.status === 0){

            return "Pending"

        }else if(item.status === 1){

            return "Processing"
        }else if(item.status === 2){

            return "Completed"
        }

    }


    renderstatusinfo(item){

        return item.status+" "+(item.canedit?" (can edit) ":"")

    }


    renderdiscountInfo(item){


        let discountsymbol = " %";
        const discountmethodtype = item.discountmethodtype;
        if(item.discounttype=="fixed"){
            discountsymbol = " SAR";
        }

        return (item.discount>0?" (Discount "+item.discount+discountsymbol+") "+discountmethodtype:"")

    }



    async callPage(page,pageSize=10,filtered = []) {


        const type = sessionStorage.getItem('managertype');
        this.setState({
            innerloading : true,
            type
        })
        const response = await  this.engine.postItem(this.pathname,"/list/"+page+"/"+pageSize,filtered);


        if(response && response.status === 200){

            let deliverydatelist = [];
            response.data.data.data.map((item, key) => {
                const deliverydatevalue = item.deliverydate.trim();
                if(!deliverydatelist.includes(deliverydatevalue)){
                    deliverydatelist.push(deliverydatevalue)
                }
            })
            deliverydatelist = _.orderBy(deliverydatelist);


            this.setState({
                    isLoading: false,
                innerloading:false,
                     deliverydatelist:deliverydatelist,
                    count:response.data.data.count,
                    page:page,
                     pageSize:pageSize,
                    deliverydate:deliverydatelist.length>1?deliverydatelist[deliverydatelist.length-1]:"",
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            deliverydate: item.deliverydate,
                            ordernumberstring: item.ordernumberstring,
                            guestname:item.user ? item.user.firstName+" "+item.user.lastName:"Guest :"+item.guestname,
                            guestemail:item.user ? item.user.email:"Guest :"+item.guestemail,
                            source:item.source,
                            dirver:item && item.driver ?item.driver.driverid:"",
                            paymenttype: item.paymenttype==1?"Cash":item.paymenttype==2?"Visa/Credit":item.paymenttype==3?"MADA online":"MADA on Delivery",
                            status:this.renderstatusinfo(item)+" " +(item.continuefromabandanctcart?" (converted)":"")+ this.renderdiscountInfo(item),
                            created:this.renderDate(item.createdAt),
                            actions: (
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage(page) {
        this.props.loadingAction(true);
        this.callPage(page,this.state.pageSize)
    }




    async updateDriverData() {
        console.log();
        this.props.loadingAction(true)
        const result = await this.engine.updateDataDriver(this.pathname)
        if(result){
            this.handlePage(0);
        }
        this.props.loadingAction(false)

    }
    globalActions() {

        return (<UncontrolledDropdown className="pull-left" >
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Global Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => {

                    this.updateDriverData()
                }}>Update Driver From Excel</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => {
                    this.handleViewBenExport()
                }}>Export</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {
                    this.handleViewBenExportAll()
                }}>Export All</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    render() {
        let {dataSource,innerloading,deliverydate,deliverydatelist,count,pageSize} = this.state;


        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number,pageSize)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })
        let columns = [
            {
                Header: "Order #",
                accessor: "ordernumberstring",
                width:100
            },
            {
                Header: "User",
                accessor: "guestname"
            },
            {
                Header: "Email",
                accessor: "guestemail"
            },
            {
                Header: "Status",
                accessor: "status",
                className:"bordermin",
                width:250
            },
            {
                Header: "Source",
                accessor: "source",
                width:100
            },
            {
                Header: "Payment",
                accessor: "paymenttype",
                width:100
            },
            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "delivery date",
                accessor: "deliverydate"
            },
            {
                Header: "Driver ID",
                accessor: "dirver"
            },

            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }

        ]




        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>


                                    <Row>


                                        <Col sm="6">
                                        <SelectRow label="Delivery Date"  name="deliverydate"  defaultValue={deliverydate}  data={this.createSelectValue(deliverydatelist)} changeInput={this.changeInput.bind(this)} />

                                            {this.globalActions()}


                                        </Col>

                                    </Row>

                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={innerloading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                innerloading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page, state.pageSize,filtered)
                                        }}
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(OrderList);
