import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import {Utilites} from "../../core/Utilites";

const DynamicPackTbl = ({ fillAttribute,initialdata,colx }) => {
    const [columns, setColumns] = useState([]);
    const [generating, setGenerating] = useState(true);
    const [readOnly, setReadOnly] = useState(true);
    const [rows, setRows] = useState(0);
    const [rowsData, setRowsData] = useState([]);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        setGenerating(false);
        processCol();
    }, [])

    useEffect(() => {

    }, [colx])

    useEffect(() => {

    }, [columns])

    const processCol = () => {
        let headcol = [];
        colx && colx.map(x=>{
            headcol.push({name:Utilites.slug(x.name?x.name:x.label),label:x.name?x.name:x.label})
        })
        headcol.push({name:"product",label:"product SKU"})
        headcol.push({name:"name",label:"product Name"})
        headcol.push({name:"unit",label:"unit of measure"})
        headcol.push({name:"quantity",label:"quantity"})
        headcol.push({name:"price",label:"price"})
        setColumns(headcol)

        fillold(headcol);
    };
    const handleChange = (e, index, index2) => {
        const fields = rowsData[index].map((r, j) => (j === index2 ? e : r));
        setRowsData(rowsData.map((rw, i) => (i === index ? fields : rw)));
    };

    const fillold=(headcol)=>{
        setModified(true);
        initialdata.map(x=>{
            setRows((prevRows) => prevRows + 1);
            let array = [];
            for (let i = 0; i < headcol.length; i++) {
                array.push(x[headcol[i].name]);
            }
            setRowsData((prevRowsData) => [...prevRowsData, array]);
        })
    }

    const addRow = () => {
        setModified(true);
        setRows((prevRows) => prevRows + 1);
        let array = [""];
        for (let i = 1; i < columns.length; i++) {
            array.push("");
        }
        setRowsData((prevRowsData) => [...prevRowsData, array]);
    };

    const deleteRow = (index) => {
        setModified(true);
        setRows((prevRows) => prevRows - 1);
        setRowsData((prevRowsData) => prevRowsData.filter((row, i) => i !== index));
    };


    useEffect(() => {
        const data = [];
        rowsData.map((row, index) => {
            const obj = { sno: index + 1 };
            columns.map((col, i) => {
                obj[col.name] = row[i];
            });
            data.push(obj);
        });
        fillAttribute(data)
    }, [rowsData]);

    return (
        <div >
            <Row className=" mb-3">
                <Col
                    md={12}
                    className="d-flex align-items-center justify-content-end"
                    style={{ marginLeft: "auto", marginRight: "70px" }}
                >
                    <span className="mr-2">Product total: {rows} </span>&nbsp;&nbsp;
                    <Button type="button" onClick={addRow} variant="outline-dark">
                        Add Product
                    </Button>
                    &nbsp;&nbsp;

                    &nbsp;&nbsp;

                </Col>
            </Row>
            <Row>
                <Col md={12} >
                            <Table responsive className=" h-100">
                                <thead>
                                <tr>
                                    <th></th>
                                    {columns.map((col, index) => (
                                        <th key={index + 999999}>

                                        </th>
                                    ))}
                                    <th></th>
                                </tr>
                                <tr className="bg-dark text-white">
                                    <th
                                        scope="col"
                                        className="d-flex align-items-center justify-content-center py-3 pb-2 border-0"
                                    >
                                        #
                                    </th>
                                    {columns.map((col, index) => (
                                        <th key={index} scope="col">
                                            <input
                                                type="text"
                                                className="form-control border-0 text-center bg-dark text-white"
                                                style={{ outline: "none", boxShadow: "none" }}
                                                readOnly={readOnly}
                                                onFocus={() => setReadOnly(false)}
                                                onBlur={() => setReadOnly(true)}
                                                value={col.label}
                                                onChange={(e) =>
                                                    setColumns(
                                                        columns.map((coln, id) =>
                                                            id === index ? e.target.value : coln.name
                                                        )
                                                    )
                                                }
                                            />
                                        </th>
                                    ))}
                                    <th className="text-center">

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {rowsData.length > 0 ? (
                                    <>
                                        {rowsData.map((data, index) => (
                                            <tr key={index + 5}>
                                                <td className="text-center">{index + 1}</td>
                                                {data.map((row, index2) => (

                                                    <td key={index2 + 988}>
                                                        {index2==2?<select  className="form-control text-center" onChange={(e) =>
                                                                handleChange(e.target.value, index, index2)
                                                                                   }>
                                                                <option selected={rowsData[index][index2]=="g"?true:false} value={"g"}>g</option>
                                                                <option selected={rowsData[index][index2]=="kg"?true:false} value={"kg"}>kg</option>
                                                                <option selected={rowsData[index][index2]=="piece"?true:false} value={"piece"}>piece</option>
                                                                <option selected={rowsData[index][index2]=="box 3.5 kg"?true:false} value={"box 3.5 kg"}>box 3.5 kg</option>
                                                                <option selected={rowsData[index][index2]=="pack 100 gms"?true:false} value={"pack 100 gms"}>pack 100 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack 125 gms"?true:false} value={"pack 125 gms"}>pack 125 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack 170 gms"?true:false} value={"pack 170 gms"}>pack 170 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack 200 gms"?true:false} value={"pack 200 gms"}>pack 200 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack 250 gms"?true:false} value={"pack 250 gms"}>pack 250 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack 300 gms"?true:false} value={"pack 300 gms"}>pack 300 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack 500 gms"?true:false} value={"pack 500 gms"}>pack 500 gms</option>
                                                                <option selected={rowsData[index][index2]=="bunch 250 gms"?true:false} value={"bunch 250 gms"}>bunch 250 gms</option>
                                                                <option selected={rowsData[index][index2]=="pack of 6"?true:false} value={"pack of 6"}>pack of 6</option>
                                                                <option selected={rowsData[index][index2]=="pack of 15"?true:false} value={"pack of 15"}>pack of 15</option>
                                                                <option selected={rowsData[index][index2]=="pack of 30"?true:false} value={"pack of 30"}>pack of 30</option>
                                                                <option selected={rowsData[index][index2]=="full fat"?true:false} value={"full fat"}>full fat</option>
                                                                <option selected={rowsData[index][index2]=="low fat"?true:false} value={"low fat"}>low fat</option>
                                                                <option selected={rowsData[index][index2]=="pack 1/2 kg"?true:false} value={"pack 1/2 kg"}>pack 1/2 kg</option>
                                                                <option selected={rowsData[index][index2]=="pack 1 kg"?true:false} value={"pack 1 kg"}>pack 1 kg</option>
                                                                <option selected={rowsData[index][index2]=="bottle 473 ml"?true:false} value={"bottle 473 ml"}>bottle 473 ml</option>
                                                                <option selected={rowsData[index][index2]=="can 250 ml"?true:false} value={"can 250 ml"}>can 250 ml</option>
                                                            </select>:
                                                        <input
                                                            type={index2<2?"text":"number"}
                                                            className="form-control text-center"
                                                            placeholder={`Enter field`}
                                                            value={rowsData[index][index2]}
                                                            onChange={(e) =>
                                                                handleChange(e.target.value, index, index2)
                                                            }
                                                        />}
                                                    </td>
                                                ))}
                                                <td className="text-center">
                                                    <Button
                                                        type="button"
                                                        onClick={() => deleteRow(index)}
                                                        variant={"outline-danger"}
                                                        size="sm"
                                                    >
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <th
                                                className="text-center py-3"
                                                colSpan={columns.length + 2}
                                            >
                                                Please click on Add Product AKU Button to add a product
                                            </th>
                                        </tr>

                                    </>
                                )}
                                </tbody>
                            </Table>

                </Col>
            </Row>
        </div>
    );
};

export default DynamicPackTbl;
