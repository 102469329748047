import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle, PaginationItem, PaginationLink, Pagination
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";

 class UserList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
            pageSize:10
        };
        this.engine = new RequestEngine();
    }




     handleDelete(valueid) {

        if(window.confirm("are you sure ? ")){
            this.props.loadingAction(true)

            this.engine.deleteUser(valueid,(response) => {
                console.log(response.status);
                if(response.status === 200){
                    this.handlePage(this.state.page,this.state.pageSize);
                }
                this.props.loadingAction(false)
            });

        }



     }


     handleVerify(valueid) {


             this.props.loadingAction(true)

             this.engine.verifyUser(valueid,(response) => {
                 console.log(response.status);
                 if(response.status === 200){
                     this.handlePage(this.state.page,this.state.pageSize);
                 }
                 this.props.loadingAction(false)
             });





     }


     handleEdit(index,dataid) {
         const data = this.state.dataSource[index].data

             this.props.history.push({
                 pathname:'/admin/user/edit/'+dataid,
                 data
             });

     }


     handleActions(index,item) {

         return (<UncontrolledDropdown>
             <DropdownToggle
                 aria-expanded={false}
                 aria-haspopup={true}
                 caret
                 className="btn-round btn-block"
                 color="primary"
                 data-toggle="dropdown"
                 id="dropdownMenuButton"
                 type="button"
             >
                 Actions
             </DropdownToggle>
             <DropdownMenu>

                 <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>
                 <DropdownItem eventKey="1" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>
                 {item.status === 0 && <DropdownItem eventKey="1" onClick={() => this.handleVerify(item._id)}>Verify</DropdownItem>}


             </DropdownMenu>
         </UncontrolledDropdown>)
     }



     checkstatus(item) {
        if(item.status === 0){

             return ("Unverified")

        }else if(item.status === 1){

            return ("Verified")
        }

     }


     putrowstatus(item){
         if(item.deleted){
             return "Deleted"
         }else
         if(item.status === 0){

             return "Unverified"

         }else if(item.status === 1){

             return "Verified"
         }

     }


     async callPage(page,pageSize=10,filtered = []) {

        let xx = this.props
         const location = xx.location.search

         const response = await  this.engine.postItem("user","/list/"+page+"/"+pageSize+location,filtered);
         if(response && response.status === 200){
             this.setState({
                     isLoading: false,
                     count:response.data.data.count,
                     page:page,
                     pageSize:pageSize,
                     dataSource: response.data.data.data.map((item, key) => {
                         return {
                             id: key,
                             data:item,
                             name: item.firstName+" "+item.lastName ,
                             email: item.email ,
                             count:item.count,
                             status: this.checkstatus(item) ,
                             raw_status: this.putrowstatus(item),
                             source:item.wpid!=0?"wordpress":item.source,
                             phone: this.processPhone(item.phone),
                             created:this.renderDate(item.createdAt),
                             actions: (
                                 // we've added some custom button actions
                                 this.handleActions(key,item)
                             )
                         };
                     })
                 }
             );
         }
         this.props.loadingAction(false);
     }


     handlePage(page) {
         this.props.loadingAction(true);
         this.callPage(page)
     }


     handleAdd() {


             this.props.history.push('/admin/user/add');


     }
     filterRawText(filter, row) {
         // Pivoted rows won't contain the column.
         //  If that's the case, we set the to true (allowing us to only filter on the current column)
         let rowValue = row[filter.id];
         if (!rowValue) {
             return true;
         }
         const index = row._index


         rowValue =  this.state.dataSource[index]["raw_"+filter.id]
         if(!rowValue){
             return;
         }
         const filterValue = filter.value;
         //const filterValue = filter.value.filterValue || "";
         //const filterType = filter.value.filterType;

         const filterType = "contains";
         switch (filterType) {
             case "contains":
                 return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
             case "starts-with":
                 return rowValue.startsWith(filterValue);
             case "ends-with":
                 return rowValue.endsWith(filterValue);
             case "matches":
                 return rowValue === filterValue;
             case "greater-than":
                 return rowValue > filterValue;
             case "less-than":
                 return rowValue < filterValue;
             default:
                 return true;
         }
     }


     render() {

         const {dataSource,isLoading,count,page,pageSize} = this.state;


         // Logic for displaying page numbers
         const pageNumbers = [];
         for (let i = 1; i <= count; i++) {
             pageNumbers.push(i);
         }
         const renderPageNumbers = pageNumbers.map((number, index) => {
             return (
                 <PaginationItem   key={index}>
                     <PaginationLink
                         onClick={()=>this.callPage(number,pageSize)}
                     >
                         {number}
                     </PaginationLink>
                 </PaginationItem>
             );
         })


         let columns = [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Email",
                accessor: "email",
                // width: 150
            },
             {
                 Header: "Order Count",
                 accessor: "count",
                 // width: 150
             },


            {
                Header: "Phone",
                accessor: "phone"
            },
             {
                 Header: "source",
                 accessor: "source"
             },

            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]




        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left"> List</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page, state.pageSize,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(UserList);
