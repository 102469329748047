import React from "react";

import Login from "views/login/Login.jsx";
import UserList from "./views/user/UserList";
import RowUserAddUpdate from "./views/user/RowUserAddUpdate";

import EditSettings from "./views/settings/EditSettings";
import ManagerList from "./views/manager/ManagerList";


import CategoryAddUpdate from "./views/category/CategoryAddUpdate";
import CategoryList from "./views/category/CategoryList";


import ProductAddUpdate from "./views/product/ProductAddUpdate";
import ProductList from "./views/product/ProductList";


import AttributeSetAddUpdate from "./views/attributeset/AttributeSetAddUpdate";
import AttributeAddUpdate from "./views/attribute/AttributeAddUpdate";
import ManagerAddUpdate from "./views/manager/ManagerAddUpdate";
import ItemUpload from "./views/product/ItemUpload";
import OrderList from "./views/order/OrderList";
import OrderInfo from "./views/order/OrderInfo";
import FileManagerScreen from "./views/media/FileManagerScreen";
import AttributeList from "./views/attribute/AttributeList";
import AttributeSetList from "./views/attributeset/AttributeSetList";
import TagList from "./views/tag/TagList";
import TagAddUpdate from "./views/tag/TagAddUpdate";
import ArticleList from "./views/article/ArticleList";
import ArticleAddUpdate from "./views/article/ArticleAddUpdate";
import CategoryarticleList from "./views/categoryarticle/CategoryarticleList";
import SearchList from "./views/search/SearchList";
import CategoryarticleAddUpdate from "./views/categoryarticle/CategoryarticleAddUpdate";
import StoreList from "./views/store/StoreList";
import StoreAddUpdate from "./views/store/StoreAddUpdate";
import BannerList from "./views/banner/BannerList";
import BannerAddUpdate from "./views/banner/BannerAddUpdate";
import CartAban from "./views/order/CartAban";
import DiscountList from "./views/discount/DiscountList";
import DiscountAddUpdate from "./views/discount/DiscountAddUpdate";
import UserVip from "./views/reports/UserVip";
import TopSellingProduct from "./views/reports/TopSellingProduct";
import OrderReports from "./views/reports/OrderReports";
import CompoundList from "./views/compound/CompoundList";
import CompoundAddUpdate from "./views/compound/CompoundAddUpdate";
import AreaList from "./views/area/AreaList";
import AreaAddUpdate from "./views/area/AreaAddUpdate";
import OutOfStockList from "./views/outofstock/OutOfStockList";
import DailyOrder from "./views/dailyorder/DailyOrder";
import AssignOrderDriver from "./views/order/AssignOrderDriver";
import DriverList from "./views/driver/DriverList";
import DriverAddUpdate from "./views/driver/DriverAddUpdate";
import CampaignAddUpdate from "./views/loyalty/CampaignAddUpdate";
import RulesAddUpdate from "./views/loyalty/RulesAddUpdate";
import PointsAddUpdate from "./views/loyalty/PointsAddUpdate";
import RewardAddUpdate from "./views/loyalty/RewardAddUpdate";
import GiftAddUpdate from "./views/loyalty/GiftAddUpdate";
import BadgeAddUpdate from "./views/loyalty/BadgeAddUpdate";
import PackageList from "./views/package/PackageList";
import PackageAddUpdate from "./views/package/PackageAddUpdate";
import DiscountUpload from "./views/discount/DiscountUpload";
import UserReport from "./views/userreport/UserReport";
import RulesList from "./views/loyalty/RulesList";
import RewardsList from "./views/loyalty/RewardsList";
import DiscountDetailsList from "./views/discount/DiscountDetailsList";
import UserActivity from "./views/useractivity/UserActivity";
import PointstransactionList from "./views/pointstransaction/PointstransactionList";
import PointsTransactionAddUpdate from "./views/pointstransaction/PointsTransactionAddUpdate";

const routes = [

    {
        path: "/shop",
        name: "Shop",
        classItem:"menuItem",
        allow: ["admin","manager","content"],
        icon: "nc-icon nc-shop",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse0",
        views: [

            {
                path: "/category",
                name: "category",
                menu: true,
                allow : ["admin","manager","content"],
                classItem:"menuItem",
                component: CategoryList,
                layout: "/admin"
            },

            {
                path: "/tag",
                name: "tag",
                menu: true,
                allow : ["admin","content"],
                //icon: "nc-icon nc-box-2",
                component: TagList,
                layout: "/admin"
            },
            {
                path: "/product",
                name: "product",
                menu: true,
                allow : ["admin","manager","content"],
                //icon: "nc-icon nc-shop",
                component: ProductList,
                layout: "/admin"
            },
            {
                path: "/outofstock",
                name: "Out Of Stock Notification",
                menu: true,
                allow : ["admin","manager"],
                //icon: "nc-icon nc-shop",
                component: OutOfStockList,
                layout: "/admin"
            },
            {
                path: "/store",
                name: "store",
                menu: true,
                allow : ["admin","manager"],
                //icon: "nc-icon nc-shop",
                component: StoreList,
                layout: "/admin"
            },
            {
                path: "/attributeset",
                name: "attribute set",
                menu: true,
                allow : ["admin"],
                // icon: "nc-icon nc-box-2",
                component: AttributeSetList,
                layout: "/admin"
            },
            {
                path: "/attribute",
                name: "attribute",
                menu: true,
                allow : ["admin"],
                // icon: "nc-icon nc-app",
                component: AttributeList,
                layout: "/admin"
            },

            {
                path: "/search",
                name: "search result",
                menu: true,
                allow : ["admin","manager","content"],
                // icon: "nc-icon nc-app",
                component: SearchList,
                layout: "/admin"
            },
            {
                path: "/compound",
                name: "compound",
                menu: true,
                allow : ["admin","manager"],
                component: CompoundList,
                layout: "/admin"
            },
            {
                path: "/area",
                name: "area",
                menu: true,
                allow : ["admin","manager"],
                component: AreaList,
                layout: "/admin"
            },
            {
                path: "/package",
                name: "basket",
                menu: true,
                allow : ["admin","manager"],
                component: PackageList,
                layout: "/admin"
            },

        ]
    },

    {
        path: "/blog",
        name: "Blog",
        classItem:"menuItem",
        allow: ["admin","manager","content"],
        icon: "nc-icon nc-paper",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse2",
        views: [
            {
                path: "/articlecategory",
                name: "category",
                menu: true,
                allow : ["admin","manager","content"],
                //icon: "nc-icon nc-paper",
                component: CategoryarticleList,
                layout: "/admin"
            },
            {
                path: "/article",
                name: "articles",
                menu: true,
                allow : ["admin","manager","content"],
                //icon: "nc-icon nc-paper",
                component: ArticleList,
                layout: "/admin"
            },

        ]
    },





    {
        path: "/sales",
        name: "Sales",
        classItem:"menuItem",
        allow : ["admin","sales","manager","orderviewer","operation"],
        icon: "nc-icon nc-money-coins",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse1",
        views: [


            {
                path: "/order",
                name: "order",
                menu: true,
                allow : ["admin","sales","manager","orderviewer","operation"],
                //icon: "nc-icon nc-money-coins",
                component: OrderList,
                layout: "/admin"
            },
            {
                path: "/cart",
                name: "Abandoned Cart",
                menu: true,
                allow : ["admin","sales","manager","operation"],
                //icon: "nc-icon nc-money-coins",
                component: CartAban,
                layout: "/admin"
            },

            {
                path: "/driver",
                name: "driver",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-tag-content",
                component: DriverList,
                layout: "/admin"
            },
            {
                path: "/dailyorder",
                name: "Daily Order",
                menu: true,
                allow : ["admin","manager","operation"],
                component: DailyOrder,
                layout: "/admin"
            }

        ]
    },


    {
        path: "/reports",
        name: "Reports",
        classItem:"menuItem",
        allow : ["admin"],
        icon: "nc-icon nc-money-coins",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse1x",
        views: [


            {
                path: "/useractivity",
                name: "User Activity",
                menu: true,
                allow : ["admin","sales","manager"],
                component: UserActivity,
                layout: "/admin"
            },
            {
                path: "/userreport",
                name: "User Report",
                menu: true,
                allow : ["admin","sales","manager"],
                component: UserReport,
                layout: "/admin"
            },

            {
                path: "/productreport",
                name: "Product Report",
                menu: true,
                allow : ["admin","sales","manager"],
                component: TopSellingProduct,
                layout: "/admin"
            },


            {
                path: "/orderreport",
                name: "Order Report",
                menu: true,
                allow : ["admin","sales","manager"],
                component: OrderReports,
                layout: "/admin"
            },




        ]
    },


    {
        path: "/loyalty",
        name: "loyalty",
        classItem:"menuItem",
        allow : ["admin"],
        icon: "nc-icon nc-money-coins",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapsle1x",
        views: [


            {
                path: "/discount",
                name: "discount",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-tag-content",
                component: DiscountList,
                layout: "/admin"
            },

            {
                path: "/discountdetails",
                name: "discount details",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-tag-content",
                component: DiscountDetailsList,
                layout: "/admin"
            },

            {
                path: "/rules",
                name: "rules",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-tag-content",
                component: RulesList,
                layout: "/admin"
            },
            {
                path: "/rewards",
                name: "rewards",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-tag-content",
                component: RewardsList,
                layout: "/admin"
            },
            {
                path: "/pointstransaction",
                name: "user points",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-tag-content",
                component: PointstransactionList,
                layout: "/admin"
            },



        ]
    },


    {
        path: "/driver/add",
        name: "add driver",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: DriverAddUpdate,
        layout: "/admin"
    },
    {
        path: "/driver/edit/:id",
        name: "edit driver",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: DriverAddUpdate,
        layout: "/admin"
    },
    {
        path: "/pointstransaction/edit/:id",
        name: "edit pointstransaction",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PointsTransactionAddUpdate,
        layout: "/admin"
    },
    {
        path: "/badge/add",
        name: "add badge",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BadgeAddUpdate,
        layout: "/admin"
    },
    {
        path: "/badge/edit/:id",
        name: "edit badge",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BadgeAddUpdate,
        layout: "/admin"
    },

    {
        path: "/rewards/add",
        name: "add reward",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RewardAddUpdate,
        layout: "/admin"
    },
    {
        path: "/rewards/edit/:id",
        name: "edit reward",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RewardAddUpdate,
        layout: "/admin"
    },


    {
        path: "/gift/add",
        name: "add gift",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: GiftAddUpdate,
        layout: "/admin"
    },
    {
        path: "/gift/edit/:id",
        name: "edit gift",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: GiftAddUpdate,
        layout: "/admin"
    },





    {
        path: "/rules/add",
        name: "add rules",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RulesAddUpdate,
        layout: "/admin"
    },
    {
        path: "/rules/edit/:id",
        name: "edit rules",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RulesAddUpdate,
        layout: "/admin"
    },


    {
        path: "/campaign/add",
        name: "add campaign",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CampaignAddUpdate,
        layout: "/admin"
    },
    {
        path: "/campaign/edit/:id",
        name: "edit campaign",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CampaignAddUpdate,
        layout: "/admin"
    },

    {
        path: "/points/add",
        name: "add points",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PointsAddUpdate,
        layout: "/admin"
    },
    {
        path: "/points/edit/:id",
        name: "edit points",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PointsAddUpdate,
        layout: "/admin"
    },

    {
        path: "/banner/add",
        name: "add banner",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BannerAddUpdate,
        layout: "/admin"
    },
    {
        path: "/banner/edit/:id",
        name: "edit banner",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BannerAddUpdate,
        layout: "/admin"
    },

    {
        path: "/banner",
        name: "banner",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-paper",
        component: BannerList,
        layout: "/admin"
    },
    {
        path: "/media",
        name: "media manager",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-paper",
        component: FileManagerScreen,
        layout: "/admin"
    },



    {
        path: "/users",
        name: "Users",
        classItem:"menuItem",
        allow: ["admin","admin2"],
        icon: "nc-icon nc-badge",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse4",
        views: [
            {
                path: "/user",
                name: "User",
                menu: true,
                allow : ["admin","admin2"],
                component: UserList,
                layout: "/admin"
            },
            {
                path: "/manager",
                name: "Manager",
                menu: true,
                allow : ["admin"],
                component: ManagerList,
                layout: "/admin"
            },

        ]
    },





    {
        path: "/store/add",
        name: "add store",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: StoreAddUpdate,
        layout: "/admin"
    },

    {
        path: "/store/edit/:id",
        name: "edit store",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: StoreAddUpdate,
        layout: "/admin"
    },


    {
        path: "/tag/add",
        name: "add tag",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: TagAddUpdate,
        layout: "/admin"
    },

    {
        path: "/tag/edit/:id",
        name: "edit tag",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: TagAddUpdate,
        layout: "/admin"
    },

    {
        path: "/article/add",
        name: "add article",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ArticleAddUpdate,
        layout: "/admin"
    },

    {
        path: "/article/edit/:id",
        name: "edit article",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ArticleAddUpdate,
        layout: "/admin"
    },


    {
        path: "/compound/add",
        name: "add compound",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: CompoundAddUpdate,
        layout: "/admin"
    },

    {
        path: "/compound/edit/:id",
        name: "edit compound",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: CompoundAddUpdate,
        layout: "/admin"
    },

    {
        path: "/area/add",
        name: "add area",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AreaAddUpdate,
        layout: "/admin"
    },

    {
        path: "/area/edit/:id",
        name: "edit area",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AreaAddUpdate,
        layout: "/admin"
    },


    {
        path: "/category/add",
        name: "add category",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryAddUpdate,
        layout: "/admin"
    },


    {
        path: "/discount/add",
        name: "add discount",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: DiscountAddUpdate,
        layout: "/admin"
    },

    {
        path: "/articlecategory/add",
        name: "add articlecategory",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryarticleAddUpdate,
        layout: "/admin"
    },
    {
        path: "/articlecategory/edit/:id",
        name: "edit articlecategory",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryarticleAddUpdate,
        layout: "/admin"
    },

    {
        path: "/package/add",
        name: "add package",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: PackageAddUpdate,
        layout: "/admin"
    },
    {
        path: "/package/edit/:id",
        name: "edit package",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: PackageAddUpdate,
        layout: "/admin"
    },

    {
        path: "/discount/edit/:id",
        name: "edit discount",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: DiscountAddUpdate,
        layout: "/admin"
    },


    {
        path: "/category/edit/:id",
        name: "edit category",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryAddUpdate,
        layout: "/admin"
    },
    {
        path: "/order/view/:id",
        name: "view order",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: OrderInfo,
        layout: "/admin"
    },
    {
        path: "/order/assign/:id",
        name: "assign order",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AssignOrderDriver,
        layout: "/admin"
    },







    {
        path: "/attribute/add",
        name: "add attribue",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AttributeAddUpdate,
        layout: "/admin"
    },


    {
        path: "/attributeset/add",
        name: "add attributeset",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AttributeSetAddUpdate,
        layout: "/admin"
    },




    {
        path: "/attribute/edit/:id",
        name: "edit attribute",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AttributeAddUpdate,
        layout: "/admin"
    },
    {
        path: "/attributeset/edit/:id",
        name: "edit attributeset",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AttributeSetAddUpdate,
        layout: "/admin"
    },

    {
        path: "/product/edit/:id",
        name: "edit product",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ProductAddUpdate,
        layout: "/admin"
    },

    {
        path: "/product/add",
        name: "add product",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ProductAddUpdate,
        layout: "/admin"
    },
    {
        path: "/product/upload",
        name: "upload product",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ItemUpload,
        layout: "/admin"
    },

    {
        path: "/discount/upload",
        name: "upload discount",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: DiscountUpload,
        layout: "/admin"
    },



    {
        path: "/login",
        name: "Login",
        mini: "L",
        menu: false,
        component: Login,
        layout: "/auth"
    },



    {
        path: "/manager",
        name: "Manager",
       // menu: true,
       // allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: ManagerList,
        layout: "/admin"
    },
    {
        path: "/user",
        name: "User",
      //  menu: true,
      //  allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: UserList,
        layout: "/admin"
    },


    {
        path: "/user/add",
        name: "Add User",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RowUserAddUpdate,
        layout: "/admin"
    },{
        path: "/user/edit/:id",
        name: "Edit User",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RowUserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/manager/edit/:id",
        name: "Edit manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerAddUpdate,
        layout: "/admin"
    },
    {
        path: "/manager/add",
        name: "add manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerAddUpdate,
        layout: "/admin"
    },





    {
        path: "/settings",
        name: "Settings",
        menu: false,
       // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: EditSettings,
        layout: "/admin"
    },


];



export default routes;
