import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col, Button
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import Checkboxrow from "../../components/Checkbox/Checkboxrow";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";
import DynamicPackTbl from "./DynamicPackTbl";
import {Editor} from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";

export default class PackageAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        const html = '';
        const contentBlock = htmlToDraft(html);

        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state = {
            isLoading: true,
            name:"",
            namear:"",
            status:false,
            slug:"",
            sku:"",
            statustxt:"active",
            basketprice:"",
            editorState : editorState,
            description:"",
            type:"medium",
            _id:"",
            fullpicture:"",
            picture:"",
            product:[],
            tprice:"0",
            variationlist:[],
            loading : false,
        };
        this.pathname = "package";
        this.idFileBase64en = "";
        this.engine = new RequestEngine();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){


            const html = edit.description;
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                ...edit,
                fullpicture:edit.picture.length>1 ? Constants.serverlink+"/upload/"+edit.picture:"",
                status:edit.status==1?true:false,
                isLoading:false,
                editorState:editorState,

            });
        }else{
            this.setState({
                isLoading:false

            });
        }


    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const {  _id,name,sku,type,weight,statustxt,variationlist,editorState,namear,tprice,basketprice }= this.state;


        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let data = {};
        data.picture=this.idFileBase64en
        data._id=_id
        data.sku = sku;
        data.name=name
        data.tprice= tprice;
        data.statustxt=statustxt;
        data.basketprice = basketprice;
        data.namear= namear;
        data.type = type;
        data.price = parseFloat(basketprice)+parseFloat(tprice);
        data.weight= weight
        data.variationlist=variationlist
        data.description = description

        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200 && response.data.success){
            this.props.history.push('/admin/'+this.pathname);
        }else{
            Utilites.showErrorMessage(response.data.message)
        }


        this.setState({
            loading: false
        })

    };

    fillAttribute(data){
        this.setState({
            variationlist:data
        })
    }

    async calculatePrice() {

        const {variationlist} = this.state

        this.setState({
            isLoading:true
        })
        const response = await this.engine.saveItem("calculateprice", variationlist)


        if (response && response.status === 200 && response.data.success) {
            let tprice = 0;
            let variationlist = [];
            response.data.data.map(((x,index)=>{
                const realvariationlist = x.fullproduct.variationlist;
                let price = 0;
                let name = x.fullproduct.name;
                const unit = x.unit;
                const varationfound = realvariationlist.find(x=>x.uom==unit)
                if(varationfound && varationfound.price){
                    const calculated  =parseFloat(parseFloat(varationfound.price)*parseFloat(x["quantity"])).toFixed(2)
                    tprice+=parseFloat(calculated);
                    price =calculated;
                    debugger
                }
                variationlist.push({ product:x["product"],
                    quantity:x["quantity"],
                    sno:x["sno"],
                    unit:x["unit"],
                    name:name,
                    price:price })
            }))
            this.setState({
                variationlist:variationlist,
                isLoading:false,
                tprice:parseFloat(tprice).toFixed(2),

            })
        } else {
            Utilites.showErrorMessage(response.data.message)
        }
    }
    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64en = result;
        });
    }
    render() {
        // taking all the states
        const {loading,isLoading,statustxt,sku,weight,type, name,editorState,variationlist,tprice,namear,basketprice }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow  label="Sku" name="sku"  data={sku} changeInput={this.changeInput.bind(this)}  />

                                        <FormRow  label="Name" name="name"  data={name} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow  label="Name ar" name="namear"  data={namear} changeInput={this.changeInput.bind(this)}  />

                                        <SelectRow label="status"  name="statustxt"  defaultValue={statustxt}  data={this.createSelectValue(["active","not active"])} changeInput={this.changeInput.bind(this)} />


                                        <SelectRow label="Basket Type"  name="type"  defaultValue={type}  data={this.createSelectValue(["small","medium","big"])} changeInput={this.changeInput.bind(this)} />

                                        <DynamicPackTbl fillAttribute={this.fillAttribute.bind(this)}  colx={[]} initialdata={variationlist}/>

                                        <Button onClick={this.calculatePrice.bind(this)}>Calculate Product price</Button>
                                        <FormRow  label="total product price" required={false} readonly={true} name="tprice" type={"number"}  data={tprice} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow  label="Basket price" name="basketprice" type={"number"}   data={basketprice} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow  label="total price" name="price" type={"number"} readonly={true} data={(parseFloat(basketprice)+parseFloat(tprice))} changeInput={this.changeInput.bind(this)}  />
                                       <FormRow  label="weight (KG)" name="weight" type={"number"}  data={weight} changeInput={this.changeInput.bind(this)}  />

                                        <Row>
                                            <Label sm="2">Description</Label>
                                            <Col sm="7" >
                                                <FormGroup   >
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="label-on-right" tag="label" sm="3">
                                                <code>required</code>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Image  (600*600) </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>







                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


