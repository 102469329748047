import React from "react";
import { CSVLink } from "react-csv";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar, Bubble, Line, Pie, Scatter} from 'react-chartjs-2';
import * as PropTypes from "prop-types";



export const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};


function Chart(props) {
    return null;
}

Chart.propTypes = {
    data: PropTypes.shape({datasets: PropTypes.any, labels: PropTypes.arrayOf(PropTypes.string)}),
    type: PropTypes.string
};
class OrderReports extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            orderbymonth:[],
            orderbyday:[],
            csvData:[]
        };
        this.pathname = "reportorder";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }


    processData(rows,label="vip") {

        let datasets= []
        let labels= []

        for (const [key, value] of Object.entries(rows)) {
            labels.push(`${key}`)
            datasets.push(rows[key].length)
        }
        let output = {
            labels,
            datasets: [
                {
                    label: label,
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }




    async callPage() {

        const response = await  this.engine.getItem(this.pathname);


        if(response && response.status === 200){






            const orderbymonth = response.data.data.orderbymonth;
            const  orderbyday= response.data.data.orderbyday;


            let csvData = []
            for (const [key, value] of Object.entries(orderbymonth)) {
                csvData.push({
                    date:`${key}`,
                    count:orderbymonth[key].length,
                    type:"order by month",
                })
            }

            for (const [key, value] of Object.entries(orderbyday)) {
                csvData.push({
                    date:`${key}`,
                    count:orderbyday[key].length,
                    type:"order by day",
                })
            }


            this.setState({
                    isLoading: false,
                orderbymonth:this.processData(orderbymonth,""),
                orderbyday:this.processData(orderbyday,""),
                csvData

                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }




    render() {
        const {isLoading,
            orderbymonth,
            orderbyday} = this.state;


        if(isLoading){
            return this.renderProgress();

        }

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List last 2000 orders</CardTitle>
                                    <CSVLink filename={"export"} className="pull-right" data={this.state.csvData}>Export </CSVLink>
                                </CardHeader>
                                <CardBody>

                                    <h3>Order By day</h3>
                                    <Line options={options} data={orderbyday} />

                                    <h3>Order By month</h3>
                                    <Line options={options} data={orderbymonth} />


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(OrderReports);
