import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle, Modal
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import {QRCodeSVG} from "qrcode.react";

class PackageList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            modalClassic2:false
        };
        this.pathname = "package";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }

    toggleModalMapClassic2 = () => {
        this.setState({
            modalClassic2: !this.state.modalClassic2
        });
    };

    renderQRCode() {
        const {modalClassic2,productid} = this.state;
        return (<>
            <Modal
                isOpen={modalClassic2}
                toggle={this.toggleModalMapClassic2}
            >
                <div className="modal-header justify-content-center english">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassic2}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">QR CODE  </h4>
                </div>
                <div className="modal-body">

                    <div style={{textAlign:"center"}}>
                        <QRCodeSVG value={"https://freshvery.com/minicheckout?id="+productid} size={256} />
                        <div>https://freshvery.com/minicheckout?id={productid}</div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic2}
                        >
                            Hide
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }
    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data
        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleQR(index,dataid) {
        const data = this.state.dataSource[index].data
        this.setState({
            modalClassic2:true,
            productid:data.slug
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem  eventKey="2" onClick={() => this.handleQR(index,item._id)}>View QR</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>

                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage() {

        const response = await  this.engine.getItem(this.pathname);


        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    dataSource: response.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            name: item.name ,
                            weight:item.weight,
                            statustxt:item.statustxt,
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        const {dataSource,isLoading} = this.state;

        let columns = [
            {
                Header: "name",
                accessor: "name"
            },
            {
                Header: "Weight",
                accessor: "weight"
            },
            {
                Header: "Status",
                accessor: "statustxt"
            },

            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>

                <div className="content">
                    {this.renderQRCode()}
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(PackageList);
