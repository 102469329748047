import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";

export default class DriverAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            firstName:"",
            lastName:"",
            phone:"",
            password:"",
            email:"",
            driverid:"",
            _id:"",
            loading : false
        };
        this.pathname = "driver";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
            });
        }
    }



    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading,  firstName,lastName, phone,password,email,driverid }= this.state;
        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="first Name" name="firstName" data={firstName}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="last Name" name="lastName" data={lastName}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Email" name="email" data={email}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Password"  name="password" data={password}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="phone" name="phone" data={phone}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow  label="Driver ID" name="driverid"  data={driverid} changeInput={this.changeInput.bind(this)}  />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


