import React from "react";
import {Row, Col, Card, CardBody, CardHeader} from "reactstrap";
import './UserActivity.css';
import {Link} from "react-router-dom";
import SelectRow from "../../components/Select/SelectRow";
import {AvForm} from "availity-reactstrap-validation";
import CoreEngine from "../../core/CoreEngine"; // Ensure to style appropriately for the lines and layout

class UserActivity extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            totaluser:6352,
            oldplatform:3877,
            newplatform:2475,
            subtitlear:"",
            link:"",
            _id:"",
            status:true,
            mobilewebfullpicture:"",
            mobilewebpicture:"",

            mobileappfullpicture:"",
            mobileapppicture:"",

            fullpicture:"",
            picture:"",

            formobile:"web",
            orderx:"0",
            loading : false,
            innerload:false,
            selecteddate:"2024-09"
        };

    }

    calculatePercent(users,idle=0){
        let {totaluser} = this.state;
        if(idle>0){
            totaluser =  idle;
        }
        return (users*100)/totaluser;
    }
    render() {
        let {totaluser,oldplatform,newplatform,selecteddate} = this.state;

        let oldplatformpercent = this.calculatePercent(oldplatform);
        let newplatformpercent = 100-oldplatformpercent;
        const vip = 21 // how many status = VIP and 2024-09!=0
        const hight = 31;
        const medium = 121;
        const low = 113;
        const active = vip+hight+medium+low
        const notactive = 131+188+572+632;


        let vippercent = this.calculatePercent(vip,active);
        let hightpercent = this.calculatePercent(hight,active);
        let mediumpercent = this.calculatePercent(medium,active);
        let lowpercent = this.calculatePercent(low,active);



        const activepercent = this.calculatePercent(active);
        const notactivepercent = 100-activepercent;

        const isnew = 76;
        const activeagain = 113;
        const idleagain = 136;


        const activeandcompound = 200;
        const activeandnotcompount = 86;
        const grouptotalcomppound =activeandcompound+activeandnotcompount
        const activeandcompoundpercent = this.calculatePercent(activeandcompound,grouptotalcomppound)
        const activeandnotcompountpercent = 100-activeandcompoundpercent;


        let idleandnotcompount = 658;
        let idleandcompount = 801;
        let groupidlecopmount=idleandnotcompount+idleandcompount
        let idleandnotcompountpercent = this.calculatePercent(idleandnotcompount,groupidlecopmount)
        let idleandncompountpercent = 100-idleandnotcompountpercent;

        let idle = 1459;
        let idlepercent = this.calculatePercent(idle);
        let vipandidle = 124;
        let vipandidlepercent = this.calculatePercent(vipandidle,idle);
        let hightandidle = 179;
        let hightandidlepercent = this.calculatePercent(hightandidle,idle);
        let mediumandidle = 544;
        let mediumandidlepercent = this.calculatePercent(mediumandidle,idle);
        let lowandidle = 612;
        let lowandidlepercent = this.calculatePercent(lowandidle,idle);
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <AvForm   className="form-horizontal" id="TypeValidation">
                                    <SelectRow label="Selected Date"  name="selecteddate"  defaultValue={selecteddate}  data={this.createSelectValue(["2024-09"])} changeInput={this.changeInput.bind(this)} />
                                    </AvForm>
                                </CardHeader>
                                {/* Total Users */}
                                <Row className="justify-content-center">
                                    <Col className="center-item total-users">
                                        <Link to={"/admin/user"}>
                                        <div className="box">
                                            <span>Total Users (all month)</span>
                                            <span className="number">{totaluser}</span>
                                        </div>
                                        </Link>
                                    </Col>
                                </Row>

                                {/* Old Platform and New Platform */}
                                <Row>
                                    <Col className="center-item old-plat">
                                        <Link to={"/admin/user?old=1"}>
                                        <div className="box">
                                            <span>Old Platform  (all month)</span>
                                            <span className="number">{oldplatform}</span>
                                            <span className="number">{oldplatformpercent.toFixed(2)}%</span>
                                        </div>
                                        </Link>
                                    </Col>
                                    <Col className="center-item new-platform">
                                        <Link to={"/admin/user?new=1"}>
                                        <div className="box">
                                            <span>New Platform  (all month)</span>
                                            <span className="number">{newplatform}</span>
                                            <span className="number">{newplatformpercent.toFixed(2)}%</span>
                                        </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </CardBody>


                            <CardBody>
                                <CardHeader>
                                    <h6>Active User</h6>
                                </CardHeader>
                                <div className="layout-grid">

                                    {/* Active, Idle, and Never Active */}
                                    <Row>
                                        <Col className="center-item active">
                                            <Link to={"/admin/user?status=active"}>
                                            <div className="box green">
                                                <span>Active</span>
                                                <span className="number">{active}</span>
                                                <span className="number">{activepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item never-active">
                                            <Link to={"/admin/user?status=neveractive"}>
                                            <div className="box yellow">
                                                <span>Never Active</span>
                                                <span className="number">{notactive}</span>
                                                <span className="number">{notactivepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                    </Row>

                                    {/* VIP, High, Medium, Low for Active */}
                                    <Row>
                                        <Col className="center-item vip active">
                                            <Link to={"/admin/user?status=vip"}>
                                            <div className="box green">
                                                <span>VIP</span>
                                                <span className="number">{vip}</span>
                                                <span className="number">{vippercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item high active">
                                            <Link to={"/admin/user?status=high"}>
                                            <div className="box green">
                                                <span>High</span>
                                                <span className="number">{hight}</span>
                                                <span className="number">{hightpercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item medium active">
                                            <Link to={"/admin/user?status=medium"}>
                                            <div className="box green">
                                                <span>Medium</span>
                                                <span className="number">{medium}</span>
                                                <span className="number">{mediumpercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item low active">
                                            <Link to={"/admin/user?status=low"}>
                                            <div className="box green">
                                                <span>Low</span>
                                                <span className="number">{low}</span>
                                                <span className="number">{lowpercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="center-item compound active">
                                            <Link to={"/admin/user?address=compound"}>
                                                <div className="box green">
                                                    <span>Compound</span>
                                                    <span className="number">{activeandcompound}</span>
                                                    <span className="number">{activeandcompoundpercent.toFixed(2)}%</span>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item non-compound active">
                                            <Link to={"/admin/user?address=noncompound"}>
                                                <div className="box green">
                                                    <span>Non-Compound</span>
                                                    <span className="number">{activeandnotcompount}</span>
                                                    <span className="number">{activeandnotcompountpercent.toFixed(2)}%</span>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>




                                </div>
                            </CardBody>

                            <CardBody>
                                <CardHeader>
                                    <h6>Idle User</h6>
                                </CardHeader>
                                <div className="layout-grid">
                                    <Row>
                                        <Col className="center-item idle">
                                            <Link to={"/admin/user?status=idle"}>
                                            <div className="box red">
                                                <span>Idle</span>
                                                <span className="number">{idle}</span>
                                                <span className="number">{idlepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                    {/* VIP, High, Medium, Low for Idle */}
                                    <Row>
                                        <Col className="center-item vip idle">
                                            <Link to={"/admin/user?status=idlevip"}>
                                            <div className="box red">
                                                <span>VIP</span>
                                                <span className="number">{vipandidle}</span>
                                                <span className="number">{vipandidlepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item high idle">
                                            <Link to={"/admin/user?status=idlehight"}>
                                            <div className="box red">
                                                <span>High</span>
                                                <span className="number">{hightandidle}</span>
                                                <span className="number">{hightandidlepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item medium idle">
                                            <Link to={"/admin/user?status=medium"}>
                                            <div className="box red">
                                                <span>Medium</span>
                                                <span className="number">{mediumandidle}</span>
                                                <span className="number">{mediumandidlepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item low idle">
                                            <Link to={"/admin/user?status=idlelow"}>
                                            <div className="box red">
                                                <span>Low</span>
                                                <span className="number">{lowandidle}</span>
                                                <span className="number">{lowandidlepercent.toFixed(2)}%</span>
                                            </div>
                                            </Link>
                                        </Col>

                                    </Row>

                                    {/* Compound and Non-Compound for Active */}


                                    {/* Compound and Non-Compound for Idle */}
                                    <Row>
                                        <Col className="center-item compound idle">
                                            <Link to={"/admin/user?status=idlecompound"}>
                                                <div className="box red">
                                                    <span>Compound</span>
                                                    <span className="number">{idleandcompount}</span>
                                                    <span className="number">{idleandncompountpercent.toFixed(2)}%</span>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item non-compound idle">
                                            <Link to={"/admin/user?status=idlenoncompound"}>
                                                <div className="box red">
                                                    <span>Non-Compound</span>
                                                    <span className="number">{idleandnotcompount}</span>
                                                    <span className="number">{idleandnotcompountpercent.toFixed(2)}%</span>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>

                            </CardBody>


                            <CardBody>
                                <CardHeader>
                                    <h6>This Month</h6>
                                </CardHeader>
                                <div className="layout-grid">
                                    {/* New This Month - Active Again and Idle Again */}
                                    <Row>
                                        <Link to={"/admin/user?status=thismonth"}>
                                        <Col className="center-item new-this-month">
                                            <div className="box gray">
                                                <span>New This Month</span>
                                                <span className="number">{isnew}</span>
                                            </div>
                                        </Col>
                                        </Link>
                                    </Row>
                                    <Row>
                                        <Col className="center-item active-again">
                                            <Link to={"/admin/user?status=activeagain"}>
                                            <div className="box green">
                                                <span>Active Again</span>
                                                <span className="number">{activeagain}</span>
                                            </div>
                                            </Link>
                                        </Col>
                                        <Col className="center-item idle-again">
                                            <Link to={"/admin/user?status=idleagain"}>
                                            <div className="box red">
                                                <span>Idle Again</span>
                                                <span className="number">{idleagain}</span>
                                            </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UserActivity;
