
import _ from "lodash";
import React from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import { CSVLink } from "react-csv";


import {Bar, Bubble, Line, Pie, Scatter} from 'react-chartjs-2';
import * as PropTypes from "prop-types";
import moment from "moment";


export const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Freshvery Bar Chart',
        },
    },
};


function Chart(props) {
    return null;
}

Chart.propTypes = {
    data: PropTypes.shape({datasets: PropTypes.any, labels: PropTypes.arrayOf(PropTypes.string)}),
    type: PropTypes.string
};
class UserVip extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            csvData:[],
            datax:[],
            vip:[],
            high:[],
            medium:[],
            lowuser:[],
            useractive:[],
            newuserlist:[]
        };
        this.pathname = "reportuser2";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }


     processData(rows,label="vip") {

        let datasets= []
        let labels= []
         rows.map((item, key) => {
            labels.push(item.user)
             datasets.push(parseInt(item.total))
        })

        let output = {
            labels,
            datasets: [
                {
                    label: label,
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }


    processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i) {

        let userstatus = "active";
        let found = useractive.find(x=>x.id==i.id);
        if(found){
            userstatus = "active"
        }
        found = useridle.find(x=>x.id==i.id);
        if(found){
            userstatus = "idle"
        }

        found = usernewactive.find(x=>x.id==i.id);
        if(found){
            userstatus = "new active"
        }

        found = usernewnoorder.find(x=>x.id==i.id);
        if(found){
            userstatus = "new not active"
        }
        return userstatus;

    }




    processActive(rows,label="vip") {

        let datasets= []
        let labels= []
        rows.map((item, key) => {
            labels.push(item.firstName+" "+item.lastName)
            datasets.push(parseInt(item.total))
        })

        let output = {
            labels,
            datasets: [
                {
                    label: label,
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }



    checkRealRank(id,testarray,yes="ACTIVE",no="IDLE") {
        const test = testarray.find(x=>x.id==id);
        if(test){
           return yes;
        }else{
            return no
        }
    }

    async callPage() {

        const response = await  this.engine.getItem(this.pathname);

        if(response && response.status === 200){



              const vip = response.data.data.vip;
              const high = response.data.data.high;
              const medium= response.data.data.medium;
              const lowuser= response.data.data.lowuser;
            const useractive= response.data.data.useractive;
            const newuserlist= response.data.data.newuserlist;
            const deaduser = response.data.data.deaduser;


            let csvData = []
            vip.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone,true),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.id,useractive,"ACTIVE","IDLE"),
                    isnew:this.checkRealRank(i.id,newuserlist,"YES","NO"),
                    status:"VIP",
                    ...i.monthorders
                })
            });

            high.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone,true),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.id,useractive,"ACTIVE","IDLE"),
                    isnew:this.checkRealRank(i.id,newuserlist,"YES","NO"),
                    status:"HIGH",
                    ...i.monthorders
                })
            });


            medium.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone,true),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.id,useractive,"ACTIVE","IDLE"),
                    isnew:this.checkRealRank(i.id,newuserlist,"YES","NO"),
                    status:"MEDIUM",
                    ...i.monthorders
                })
            });

            lowuser.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone,true),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.id,useractive,"ACTIVE","IDLE"),
                    isnew:this.checkRealRank(i.id,newuserlist,"YES","NO"),
                    status:"LOW",
                    ...i.monthorders
                })
            });

            deaduser.map(i=>{
                var start = moment();
                var end = moment(i.createdAt);
                const days = start.diff(end, 'days') ;
                csvData.push({
                    id:i.id,
                    full_name:i.full_name,
                    phone:this.processPhone(i.phone,true),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:"IDLE",
                    isnew:days<30?"YES":"NO",
                    status:"NEVER ACTIVE",
                  //  ...i.monthorders
                })
            });






            let onerowofmonst = useractive[0]?.monthorders;
            if(!onerowofmonst){
                onerowofmonst = high[0]?.monthorders;
            }
            if(!onerowofmonst){
                onerowofmonst = vip[0]?.monthorders;
            }

            for (const [key, value] of Object.entries(onerowofmonst)) {
                onerowofmonst[key]= "0"
            }



            csvData= _.uniqBy(csvData, function (e) {
                return e.email;
            });
            this.setState({
                    isLoading: false,
                vip:this.processData(vip),
                medium:this.processData(medium,""),
                lowuser:this.processData(lowuser,""),
                csvData
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }




    render() {
        const {vip,isLoading,
            activeuser,
            hightuser,
            medium,
            lowuser} = this.state;



        if(isLoading){
            return this.renderProgress();

        }

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                    <CSVLink filename={"export"} className="pull-right" data={this.state.csvData}>Export </CSVLink>
                                </CardHeader>
                                <CardBody>

                                    <h3>VIP( Over 1,000 SAR monthly/ AVG last 90 days ) </h3>
                                    <Bar options={options} data={vip} />


                                    <h3>High ( Between 500 & 1000 Monthly/ AVG last 90 days )</h3>
                                    <Line options={options} data={hightuser} />


                                    <h3>Medium ( Between 250 & 500 Monthly/ AVG last 90 days )</h3>
                                    <Line options={options} data={medium} />

                                    <h3>Low ( Less than 250 monthly / AVG last 90 days )</h3>
                                    <Line options={options} data={lowuser} />


                                    <h3>Active ( active for a period last 30 days )</h3>
                                    <Line options={options} data={activeuser} />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(UserVip);
